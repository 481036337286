var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
    'items-per-page-options': [_vm.pagination.rowsPerPage],
  },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.decoratedItems,"mobile-breakpoint":0,"page":_vm.pagination.page,"server-items-length":_vm.pagination.totalItems,"disable-sort":""},on:{"click:row":_vm.tableMixin_onRowClick,"update:page":_vm.tableMixin_changePage},scopedSlots:_vm._u([{key:"item.part.inventory_item.title",fn:function(ref){
  var item = ref.item;
return [(item.part && item.part.inventory_item)?_c('router-link',{staticClass:"link",attrs:{"to":{
        name: 'inventory-items',
        query: { title: item.part.inventory_item.title },
      }}},[_vm._v(" "+_vm._s(item.part.inventory_item.title)+" ")]):_vm._e(),(item.purchase)?_c('router-link',{staticClass:"link",attrs:{"to":{
        name: 'inventory-items',
        query: { title: item.purchase.inventory_item.title },
      }}},[_vm._v(" "+_vm._s(item.purchase.inventory_item.title)+" ")]):_vm._e()]}},{key:"item.quantity",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInventoryItemQuantity( item.quantity, item.purchase ? item.purchase.material_unit : item.part.inventory_item.material_unit, item.purchase ? item.purchase.inventory_item.unit_a_title : item.part.inventory_item.unit_a_title, item.purchase ? item.purchase.inventory_item.unit_b_title : item.part.inventory_item.unit_b_title, item.purchase ? item.purchase.inventory_item.unit_a_b_ratio : item.part.inventory_item.unit_a_b_ratio ))+" ")]}},{key:"item.part.order.order_no",fn:function(ref){
      var item = ref.item;
return [(item.part && item.part.order)?_c('router-link',{staticClass:"link",attrs:{"to":("/orders?search=" + (item.part.order.order_no))}},[_vm._v(" "+_vm._s(item.part.order.order_no)+" ")]):_vm._e(),(item.inventory_item_purchase_id)?_c('router-link',{staticClass:"link",attrs:{"to":("/inventory-item-purchases?search=" + (item.purchase.inventory_item.title))}},[_vm._v(" "+_vm._s(_vm.$t('purchase'))+"#"+_vm._s(item.inventory_item_purchase_id)+" ")]):_vm._e()]}},{key:"item.comment",fn:function(ref){
      var item = ref.item;
return [_c('BaseTruncatedField',{attrs:{"text":item.comment}})]}},{key:"item.part.order.client_contact.name",fn:function(ref){
      var item = ref.item;
return [(item.part && item.part.order && item.part.order.client_contact)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({staticClass:"link"},on),[_vm._v(" "+_vm._s(item.part.order.client_contact.name)+" ")])]}}],null,true)},[_c('ContactCard',{attrs:{"contact-id":item.client.id}})],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"item":item}})]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.quantity",fn:function(){return [_vm._v(" "+_vm._s(_vm.getInventoryItemQuantity( item.quantity, item.purchase ? item.purchase.material_unit : item.part.inventory_item.material_unit, item.purchase ? item.purchase.inventory_item.unit_a_title : item.part.inventory_item.unit_a_title, item.purchase ? item.purchase.inventory_item.unit_b_title : item.part.inventory_item.unit_b_title, item.purchase ? item.purchase.inventory_item.unit_a_b_ratio : item.part.inventory_item.unit_a_b_ratio ))+" ")]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row>
    <v-col cols="12" sm="6" md="4" lg="3">
      <BaseAutocomplete
        v-model="filters.inventory_item_id"
        :initial-item="selectedInventoryItem"
        :search-function="searchInventoryItems"
        :label="$t('inventory_item')"
        item-text="title"
        clearable
        @update:initial-item="onInventoryItemSelect"
      />
    </v-col>

    <v-col cols="12" sm="6" md="4" lg="3">
      <BaseAutocomplete
        v-model="filters.client_id"
        :initial-item="selectedClient"
        :search-function="searchClients"
        :label="$t('client')"
        clearable
        @update:initial-item="onClientSelect"
      />
    </v-col>

    <v-col cols="12" sm="6" md="4" lg="3">
      <v-text-field
        v-model="filters.order_no"
        :label="$t('order_no')"
        class="mt-0"
        clearable
        hide-details
        @input="handleDebounce"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseAutocomplete from '@/components/base/BaseAutocomplete';
import inventoryItemService from '@/api/inventory-item-service';
import contactService from '@/api/contact-service';

let timerId;

export default {
  name: 'InventoryItemDispatchFilter',

  components: { BaseAutocomplete },

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedClient: null,
      selectedInventoryItem: null,
    };
  },

  created() {
    const { inventory_item_id, inventory_item_title } = this.$route.query;
    if (inventory_item_id && inventory_item_title) {
      this.selectedInventoryItem = {
        id: +inventory_item_id,
        title: inventory_item_title,
      };
    }
  },

  methods: {
    searchInventoryItems: inventoryItemService.search,
    searchClients: contactService.searchClients,

    handleDebounce() {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        this.$emit('change', this.filters);
      }, 500);
    },

    onInventoryItemSelect(item) {
      this.selectedInventoryItem = item;
      this.filters.inventory_item_id = item?.id;
      this.updateFilter();
    },

    onClientSelect(item) {
      this.selectedClient = item;
      this.filters.client_contact_id = item?.id;
      this.updateFilter();
    },

    updateFilter() {
      this.$emit('change', this.filters);
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :footer-props="{
      'items-per-page-options': [pagination.rowsPerPage],
    }"
    :headers="tableMixin_displayedHeaders"
    :items="decoratedItems"
    :mobile-breakpoint="0"
    :page="pagination.page"
    :server-items-length="pagination.totalItems"
    disable-sort
    @click:row="tableMixin_onRowClick"
    @update:page="tableMixin_changePage"
  >
    <template v-slot:item.part.inventory_item.title="{ item }">
      <router-link
        v-if="item.part && item.part.inventory_item"
        :to="{
          name: 'inventory-items',
          query: { title: item.part.inventory_item.title },
        }"
        class="link"
      >
        {{ item.part.inventory_item.title }}
      </router-link>

      <router-link
        v-if="item.purchase"
        :to="{
          name: 'inventory-items',
          query: { title: item.purchase.inventory_item.title },
        }"
        class="link"
      >
        {{ item.purchase.inventory_item.title }}
      </router-link>
    </template>

    <template v-slot:item.quantity="{ item }">
      {{
        getInventoryItemQuantity(
          item.quantity,

          item.purchase ? item.purchase.material_unit : item.part.inventory_item.material_unit,

          item.purchase
            ? item.purchase.inventory_item.unit_a_title
            : item.part.inventory_item.unit_a_title,
          item.purchase
            ? item.purchase.inventory_item.unit_b_title
            : item.part.inventory_item.unit_b_title,
          item.purchase
            ? item.purchase.inventory_item.unit_a_b_ratio
            : item.part.inventory_item.unit_a_b_ratio,
        )
      }}
    </template>

    <template v-slot:item.part.order.order_no="{ item }">
      <router-link
        v-if="item.part && item.part.order"
        :to="`/orders?search=${item.part.order.order_no}`"
        class="link"
      >
        {{ item.part.order.order_no }}
      </router-link>

      <router-link
        v-if="item.inventory_item_purchase_id"
        :to="`/inventory-item-purchases?search=${item.purchase.inventory_item.title}`"
        class="link"
      >
        {{ $t('purchase') }}#{{ item.inventory_item_purchase_id }}
      </router-link>
    </template>

    <template v-slot:item.comment="{ item }">
      <BaseTruncatedField :text="item.comment" />
    </template>

    <template v-slot:item.part.order.client_contact.name="{ item }">
      <v-menu
        v-if="item.part && item.part.order && item.part.order.client_contact"
        :close-on-content-click="false"
        offset-y
        nudge-bottom="8"
      >
        <template v-slot:activator="{ on }">
          <span v-on="on" class="link">
            {{ item.part.order.client_contact.name }}
          </span>
        </template>
        <ContactCard :contact-id="item.client.id" />
      </v-menu>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu :actions="getRowActions(item)" :item="item" />
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.quantity>
          {{
            getInventoryItemQuantity(
              item.quantity,

              item.purchase ? item.purchase.material_unit : item.part.inventory_item.material_unit,

              item.purchase
                ? item.purchase.inventory_item.unit_a_title
                : item.part.inventory_item.unit_a_title,
              item.purchase
                ? item.purchase.inventory_item.unit_b_title
                : item.part.inventory_item.unit_b_title,
              item.purchase
                ? item.purchase.inventory_item.unit_a_b_ratio
                : item.part.inventory_item.unit_a_b_ratio,
            )
          }}
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import { format } from 'date-fns';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import tableMixin from '@/mixins/table-mixin';
import BaseTruncatedField from '@/components/base/BaseTruncatedField';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import ContactCard from '@/components/ContactCard';
import { getInventoryItemQuantity } from '@/util/inventory-item-quantity';

export default {
  name: 'InventoryItemDispatchTable',

  components: {
    ContactCard,
    BaseActionMenu,
    BaseTruncatedField,
    BaseExpandedTableRow,
  },

  mixins: [tableMixin],

  props: {
    rows: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('inventory_item'),
          value: 'part.inventory_item.title',
        },
        {
          text: this.$t('quantity'),
          value: 'quantity',
          hidden: 'xsOnly',
        },
        {
          text: `${this.$t('order_no')} / ${this.$t('purchase_no')}`,
          value: 'part.order.order_no',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('client'),
          value: 'part.order.client.name',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('dispatched_by'),
          value: 'user.person.full_name',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('dispatched_at'),
          value: 'created_at',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('invoice_no'),
          value: 'comment',
          hidden: 'mdAndDown',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];
    },

    decoratedItems() {
      return this.rows.map(r => ({
        ...r,
        created_at: format(new Date(r.created_at), 'yyyy-MM-dd'),
      }));
    },
  },

  methods: {
    getInventoryItemQuantity,

    getRowActions() {
      return [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ];
    },
  },
};
</script>

<style scoped></style>

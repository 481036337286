<template>
  <div class="page-wrapper">
    <BaseTableLoader :loading="isDataLoading">
      <div>
        <BaseTableHeader :title="$t('dispatch_history')" />

        <InventoryItemDispatchFilter
          :filters="inventoryItemDispatchFilterParams"
          class="px-4"
          @change="getInventoryItemDispatches(1)"
        />

        <InventoryItemDispatchTable
          :rows="inventoryItemDispatchArray"
          :pagination="inventoryItemDispatchPagination"
          @edit="crudMixin_openForm('inventoryItemDispatch', $event)"
          @delete="deleteInventoryItemDispatch"
        />
      </div>
    </BaseTableLoader>

    <v-dialog
      v-model="isInventoryItemDispatchFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <InventoryItemDispatchForm
        :dialog="isInventoryItemDispatchFormOpen"
        :form-item="inventoryItemDispatchFormItem"
        @cancel="isInventoryItemDispatchFormOpen = false"
        @create="crudMixin_created('inventoryItemDispatch', $event)"
        @update="crudMixin_updated('inventoryItemDispatch', $event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import inventoryItemDispatchService from '@/api/inventory-item-dispatch-service';
import BaseTableLoader from '@/components/base/BaseTableLoader';
import BaseTableHeader from '@/components/base/BaseTableHeader';
import InventoryItemDispatchFilter from '@/components/filters/InventoryItemDispatchFilter';
import InventoryItemDispatchTable from '@/components/tables/InventoryItemDispatchTable';
import InventoryItemDispatchForm from '@/components/forms/InventoryItemDispatchForm';

export default {
  name: 'InventoryItemDispatches',

  components: {
    InventoryItemDispatchForm,
    InventoryItemDispatchTable,
    InventoryItemDispatchFilter,
    BaseTableHeader,
    BaseTableLoader,
  },

  mixins: [crudMixin],

  data() {
    return {
      inventoryItemDispatchArray: [],
      inventoryItemDispatchPagination: {
        page: 1,
      },
      inventoryItemDispatchFormItem: {},
      inventoryItemDispatchFilterParams: {},
      isInventoryItemDispatchFormOpen: false,
      isDataLoading: true,
    };
  },

  created() {
    const { inventory_item_id } = this.$route.query;
    if (inventory_item_id) {
      this.inventoryItemDispatchFilterParams.inventory_item_id = inventory_item_id;
    }
    this.getInventoryItemDispatches();
  },

  methods: {
    getInventoryItemDispatches(pageNo = 1) {
      this.crudMixin_getPage(
        inventoryItemDispatchService.getPage,
        inventoryItemDispatchService.model,
        pageNo,
        this.inventoryItemDispatchFilterParams,
      );
    },

    deleteInventoryItemDispatch(item) {
      this.crudMixin_delete(
        inventoryItemDispatchService.delete,
        inventoryItemDispatchService.model,
        item,
      );
    },
  },
};
</script>

<style scoped></style>
